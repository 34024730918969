<template>
  <div class="container">
    <div class="logged-in-label">Logged in as: </div>
    <div class="logged-in-info"> {{ loggedInUser }}</div>
  </div>
  <Header />
  <div class="container">
    <h1>Edit Trainee</h1>

    <div class="current-time-container">
      <button class="btn btn-primary mr-3" id=homeButton @click="goHome">Back to Home</button>
      <div class="time-label">Time:</div>
      <div class="current-time">{{ currentTime }}</div>
    </div>

    <div class="add">
      <form @submit.prevent="updateTrainee(newTrainee.trainee_id)">
        <div class="form-group">
          <label for="first_name">First Name:</label>
          <input type="text" class="form-control" id="first_name" name="first_name" placeholder="Enter First Name"
            v-model="newTrainee.first_name" />
        </div>
        <div class="form-group">
          <label for="family_name">Family Name:</label>
          <input type="text" class="form-control" id="family_name" name="family_name" placeholder="Enter Family Name"
            v-model="newTrainee.family_name" />
        </div>
        <div class="form-group">
          <label for="UID">Trainee UID:</label>
          <input type="text" class="form-control" id="UID" name="UID" placeholder="Enter Trainee UID"
            v-model="newTrainee.UID" />
        </div>
        <div class="form-group">
          <label for="t_username">Trainee Username:</label>
          <input type="text" class="form-control" id="t_username" name="t_username" placeholder="Create Trainee Username"
            v-model="newTrainee.t_username" />
        </div>
        <div class="form-group">
          <label for="t_password">Trainee Password:</label>
          <input type="password" class="form-control" id="t_password" name="t_password"
            placeholder="Create Trainee Password" v-model="newTrainee.t_password" />
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="text" class="form-control" id="email" name="email" placeholder="Enter Email"
            v-model="newTrainee.email" />
        </div>
        <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
          <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        </div>
        <button class="btn btn-primary" type="submit">Update Trainee</button>
      </form>
    </div>
  </div>
</template>



<script>
import Header from './Header.vue'
import axios from 'axios'

export default {
  name: 'EditTrainee',
  components: {
    Header
  },
  data() {
    return {
      trainees: [],
      newTrainee: {
        first_name: '',
        family_name: '',
        UID: '',
        t_username: '',
        t_password: '',
        email: ''
      },
      showAlert: false,
      currentTime: '',
      errorMessages: '',
      errorMessage: '',
      loggedInUser: '',
    }
  },
  async mounted() {
    this.updateCurrentTime() // Update current time immediately
    setInterval(this.updateCurrentTime, 1000) // Update current time every second
    await this.fetchTrainees()

    this.getTraineeData(this.$route.params.trainee_id);
    this.fetchLoggedInUser();
  },

  methods: {
    getTraineeData(trainee_id) {
      try {
        const token = localStorage.getItem('Examiner-token');
        const headers = { Authorization: `Bearer ${token}` };

        axios.get(`http://46.101.94.150/api/trainees/${trainee_id}`, { headers })
          .then(res => {
            this.newTrainee = res.data.trainee;
          });
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },
    updateCurrentTime() {
      const now = new Date()
      const hours = now.getHours().toString().padStart(2, '0')
      const minutes = now.getMinutes().toString().padStart(2, '0')
      const seconds = now.getSeconds().toString().padStart(2, '0')
      this.currentTime = `${hours}:${minutes}:${seconds}`
    },
    async updateTrainee() {
      try {
        const trainee_id = this.$route.params.trainee_id;
        const token = localStorage.getItem('Examiner-token');
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(`http://46.101.94.150/api/trainees/${trainee_id}`, {
          uid: this.newTrainee.UID, // Updated field name to 'uid'
          first_name: this.newTrainee.first_name,
          family_name: this.newTrainee.family_name,
          email: this.newTrainee.email,
          t_username: this.newTrainee.t_username, // Updated field name to 'username'
          t_password: this.newTrainee.t_password, // Updated field name to 'password'
        }, { headers });

        if (response.status === 200) {
          window.alert('Trainee updated Successfully')
          this.trainees.push(response.data.trainee);
          this.$router.push({ name: 'ViewTrainees' });
          this.showAlert = false;
        }
      } catch (error) {
        console.error('Error adding trainee:', error.response);
        this.errorMessage = error.response?.data?.error;
        console.log(this.errorMessage);
        this.showAlert = true;
      }
    },

    async fetchLoggedInUser() {
      try {
        const token = localStorage.getItem('Examiner-token');
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get('http://46.101.94.150/api/user', { headers });
        this.loggedInUser = response.data.username;
      } catch (error) {
        console.error('An error occurred while fetching the logged-in user:', error);
      }
    },

    async fetchTrainees() {
      try {
        const token = localStorage.getItem('Examiner-token')
        if (!token) {
          // Handle missing Examiner token
          return
        }

        const headers = {
          Authorization: `Bearer ${token}`
        }

        const response = await axios.get('http://46.101.94.150/api/trainees', { headers })

        if (response.status === 200) {
          this.trainees = response.data.trainees
        }
      } catch (error) {
        console.error('Error retrieving trainees:', error)
        // Handle error
      }
    },
    goHome() {
      this.$router.push({ name: 'ExaminerHome' }) // Go back to the home page
    },
  },
}
</script>