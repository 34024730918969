<template>
  <div class="sign-modal-overlay" @click="closeModal">
    <div class="sign-modal" @click.stop>
      <div class="sign-modal-content" @click.stop>
        <div id="app" class="bg-secondary">
          <div class="row bg-secondary" style="margin-right: 0px; margin-left: 0px">
            <div class="col-sm-6 bg-secondary">
              <div class="card">
                <div class="card-body" style="background: darkgray">
                  <form action="" class="card-img-top" @submit.prevent="sign" method="POST" autocomplete="off">
                    <vueSignature ref="signature" id="sig" :sigOption="option" :disabled="disabled" :velocity="1.1"
                      :defaultUrl="dataUrl">
                    </vueSignature>
                  </form>
                  <button @click="sign" variant="success" class="m-1">Save</button>
                  <button @click="clear" variant="info" class="m-1">Clear</button>
                </div>
                <div></div>
              </div>
            </div>
            <div class="col-sm-6 bg-secondary">
              <div class="card bg-secondary">
                <div class="card-body">
                  <table class="table table-bordered">
                    <div v-for="book in books" :key="book.id">
                      <div v-if="!book.id == 0">
                        <ul>
                          <li style="background-color:red">
                            <!-- <img :src="'/api/store_image/fetch_image/' + book.id " style="width: 180px; height: 50px;" alt="image" /> -->
                            <img :src="book.user_image" alt="Signature" style="width: 300px; height: 100%;" />
                            <div class="btn-group" role="group">
                              <button class="btn btn-danger" @click="deleteBook(book.id)"> Delete </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
<script>
import vueSignature from "vue-signature"; // install vue-signature or download in my Github
import axios from "axios";

export default {
  name: 'TraineeSignature',
  components: {
    vueSignature,
  },
  data() {
    return {
      books: [],
      png: null,
      user_image: null,
      error: "",
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "#FAF9F6",
      },
      disabled: false,
      dataUrl: "",
    };
  },

  mounted() {
    this.getPosts();
  },
  methods: {
    getPosts() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const userId = localStorage.getItem('user_id');

      axios.get(`/api/fetch_image/${userId}`, { headers })
        .then((response) => {
          this.books = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching posts:", error);
        });
    },
    sign() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const userId = localStorage.getItem('user_id');
      var _this = this;
      var png = _this.$refs.signature.save();
      axios.post(`/api/sign`, { user_image: png, user_id: userId }, { headers }).then((result) => {
        console.log(result);
        axios.get(`/api/fetch_image/${userId}`, { headers }).then((response) => {
          this.books = response.data;
        });
      });
      _this.$refs.signature.clear();
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    deleteBook(id) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.delete(`/api/store_image/delete/${id}`, { headers })
        .then((response) => {
          let i = this.books.findIndex((item) => item.id === id); // find index of your object
          if (i !== -1) {
            this.books.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.error("Error deleting book:", error);
        });
    },

  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

button.m-1:hover {
  background-color: rgb(224, 224, 224);
}

.btn-group>.btn:first-child {
  margin-left: 10px;
}

.sign-modal-overlay {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.sign-modal {
  background-color: #fff;
  max-width: 90%;
  min-height: 10%;
  min-width: 60%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  display: block;
  z-index: 1001;
  top:30%;
  left:5%;
}

</style>
  
  
  