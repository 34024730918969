<template>
  <div>
    <div class="container">
      <div class="logged-in-label">Logged in as: </div>
      <div class="logged-in-info"> {{ loggedInUser }}</div>
    </div>
    <Header />
    <div class="container">
      <h1>Add Instructor</h1>

      <div class="current-time-container">
        <button class="btn btn-primary mr-3" id=homeButton @click="goHome">Back to Home</button>
        <div class="time-label">Time:</div>
        <div class="current-time">{{ currentTime }}</div>
      </div>
      <div class="add">
        <form @submit.prevent="addInstructor">
          <div class="form-group">
            <label for="first_name">First Name:</label>
            <input type="text" class="form-control" id="first_name" name="first_name" placeholder="Enter First Name"
              v-model="instructor.first_name" />
          </div>
          <div class="form-group">
            <label for="family_name">Family Name:</label>
            <input type="text" class="form-control" id="family_name" name="family_name" placeholder="Enter Family Name"
              v-model="instructor.family_name" />
          </div>
          <div class="form-group">
            <label for="UID">Instructor UID:</label>
            <input type="text" class="form-control" id="UID" name="UID" placeholder="Enter Instructor UID"
              v-model="instructor.UID" />
          </div>
          <div class="form-group">
            <label for="i_username">Instructor Username:</label>
            <input type="text" class="form-control" id="i_username" name="i_username"
              placeholder="Create Instructor Username" v-model="instructor.i_username" />
          </div>
          <div class="form-group">
            <label for="i_password">Instructor Password:</label>
            <input type="password" class="form-control" id="i_password" name="i_password"
              placeholder="Create Instructor Password" v-model="instructor.i_password" />
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="text" class="form-control" id="email" name="email" placeholder="Enter Email"
              v-model="instructor.email" />
          </div>
          <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
            <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
          </div>
          <button class="btn btn-primary" type="submit">Add new instructor</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Header from './Header.vue';
import axios from 'axios';
export default {
  name: 'AddInstructor',
  components: {
    Header
  },
  data() {
    return {
      instructor: {
        UID: '',
        first_name: '',
        family_name: '',
        i_username: '',
        i_password: '',
        email: '',
      },
      currentTime: '',
      showAlert: false,
      instructors: [],
      errorMessage: '',
      loggedInUser: '',
    };
  },
  methods: {
    async addInstructor() {
      try {
        const token = localStorage.getItem('Examiner-token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.post('http://46.101.94.150/api/instructors', {
          uid: this.instructor.UID,
          first_name: this.instructor.first_name,
          family_name: this.instructor.family_name,
          username: this.instructor.i_username,
          password: this.instructor.i_password,
          email: this.instructor.email,
        }, { headers });


        if (response.status === 200) {
          window.alert('Instructor added Successfully')
          this.instructors.push(response.data.instructor);
          this.instructor = {
            first_name: '',
            family_name: '',
            UID: '',
            i_username: '',
            i_password: '',
            email: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
        }
        // Reset form fields
        this.instructor = {
          UID: '',
          first_name: '',
          family_name: '',
          i_username: '',
          i_password: '',
          email: '',
        };
        // Show success message or perform any other actions
      } catch (error) {
        console.error('Error adding instructor:', error.response);
        this.errorMessage = error.response?.data?.error;
        console.log(this.errorMessage);
        this.showAlert = true;
      }
    },
    updateCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },
    async fetchInstructors() {
      try {
        const token = localStorage.getItem('Examiner-token');
        if (!token) {
          console.display('unauthorized');
          return;
        }
        const headers = {
          Authorization: `Bearer ${token}`
        };
        const response = await axios.get('http://46.101.94.150/api/instructors', { headers });
        if (response.status === 200) {
          this.instructors = response.data.instructors;
        }
      } catch (error) {
        console.error('Error retrieving instructors:', error);
      }
    },
    async fetchLoggedInUser() {
      try {
        const token = localStorage.getItem('Examiner-token');
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get('http://46.101.94.150/api/user', { headers });
        this.loggedInUser = response.data.username;
      } catch (error) {
        console.error('An error occurred while fetching the logged-in user:', error);
      }
    },
    goHome() {
      this.$router.push({ name: 'ExaminerHome' }) // Go back to the previous page
    },
  },
  async mounted() {
    this.updateCurrentTime(); // Update current time immediately
    setInterval(this.updateCurrentTime, 1000); // Update current time every second
    await this.fetchInstructors();
    this.fetchLoggedInUser();
  },
};
</script>