<template>
  <div class="modal-overlay" @click="closeModal">
    <div class="products-modal" @click.stop>
      <div class="modal-content" @click.stop>
        <div id="app" class="bg-secondary">
          <div class="row bg-secondary" style="margin-right: 0px; margin-left: 0px">
            <div class="col-md bg-secondary">
              <div class="card">
                <div class="card-body" style="background: darkgray">

                  <!-- this is form to inser new Signature -->
                  <form action="" class="card-img-top" @submit.prevent="sign" method="POST" autocomplete="off">
                    <!-- we used vueSignature , so write in form vueSignature -->
                    <vueSignature ref="signature" id="sig" :sigOption="option" :disabled="disabled">
                    </vueSignature>
                  </form>
                  <!-- this button is to insert new data or clear -->
                  <button @click="sign" variant="success" class="m-1">Save</button>
                  <button @click="clear" variant="info" class="m-1">Clear</button>
                  <button @click="closeModal" variant="info" class="m-1">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
<script>
import vueSignature from "vue-signature"; // install vue-signature or download in my Github
import axios from "axios";

export default {
  components: {
    vueSignature,
  },
  data() {
    return {
      books: [],
      png: null,
      error: "",
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "#FAF9F6",
      },

      disabled: false,
      dataUrl: "",
      userSignatures: [],
    };
  },

  mounted() {
    const userId = localStorage.getItem('user_id');
    this.fetchUserSignatures(userId);
  },
  methods: {
    closeModal() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
    async fetchUserSignatures(userId) {
      
    //eslint-disable-next-line
    debugger;
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const response = await axios.get(`/api/fetch_image/${userId}`, { headers });

        this.userSignatures = response.data.signatures;
        console.log(this.user_id)
      } catch (error) {
        console.error("Error fetching user signatures:", error);
      }
    },
   
    sign() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const userId = localStorage.getItem('user_id');

      var _this = this;
      var png = _this.$refs.signature.save();

      // Include user_id in the payload
      axios.post(`/api/sign`, { user_image: png, user_id: this.userId }, { headers }).then((result) => {
        console.log(result);

        axios.get(`/api/fetch_image/${userId}`, { headers }).then((response) => {
          this.books = response.data;
          console.log("trainee sign",)
        });
      }).catch((error) => {
        console.error("There was an error saving the signature:", error);
      });

      _this.$refs.signature.clear();
    },

    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    deleteBook(id) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.post(`/api/store_image/delete/${id}`, { headers })
        .then((response) => {
          let i = this.books.findIndex((item) => item.id === id); // find index of your object
          if (i !== -1) {
            this.books.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.error("Error deleting book:", error);
        });
    },

  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

button.m-1:hover {
  background-color: rgb(224, 224, 224);
}

.btn-group>.btn:first-child {
  margin-left: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.products-modal {
  background-color: #fff;
  max-width: 90%;
  min-height: 50%;
  min-width: 60%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  display: block;
  z-index: 1001;
}
</style>
  
  
  