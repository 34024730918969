<template>
   <page-header :is-assessor="isAssessor"></page-header>
  <instructor-home @role-changed="isAssessor = $event"></instructor-home>
  <router-view />
  <head>
    <link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"/>
  </head>
</template>
  <title>{{document.title}}</title>

<script>

export default {
  name: 'App',
  data() {
    return {
      isAssessor: false,
    };
  },
  mounted() {
    document.title = "elogbook"; // TabName
  }
}
</script>

<style>


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} 
body {
  padding:0;
  margin:0;
}
.table td, .table th {
    vertical-align: middle !important;
}
.skinny-column {
  width: 6%; 
}

.wider-column {
  width: 40%; 
}

.logo {
  padding:0px;
  margin:0px;
}


input #logbookName {
    margin-bottom: 20px;
}

.logged-in-info {
  position: absolute;
  top: 56px;
  left: 120px;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.logged-in-label {
  position: absolute;
  top: 56px;
  left: 10px;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
}

h1 {
  padding-top: 20px;
}


h2 {
  margin-left: 5%;
  text-align:left;
}

/* .custom-table {
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 5%;
}

.custom-table th,
.custom-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
  
}

.custom-table th {
  background-color: rgba(77, 123, 161, 0.144); 
  font-size: 14px;
} */


.register input, .login input, .add input {
    width: 300px;
    height: 20px;
    padding-left: 20px;
    display: block;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid rgb(0, 96, 134);
}

.register button, .login button, .add button:hover {
  background-color: #1b78db;
  border-color: #002c5c;
  color: aliceblue;
}

.register button:hover,
.login button:hover,
.add button:hover {
  transform: scale(1.05); /* Increase size on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
  border-color: #002c5c;
}

.register button,
.login button,
.add button {
  width: 200px;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #1b78db;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.form-group {
    margin-bottom: 0.5rem !important;
}

.current-time-container {
  position: absolute;
  top: 60px;
  right: 10px;
  display: flex;
  align-items: center;

}

.time-label {
  margin-right: 5px;
  font-weight: bold;
}

.current-time {
  font-size: 16px;
}


.form-group label {
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

.form-group input {
  display: inline-block;
  width: 300px;
  align-items: center;
  height: 35px;
  padding-left: 10px;
}

span .dropdown-icon {
  transform: rotate(0deg);
  transition: all 0.1s ease;
  &.dropdown {
    transform: rotate(180deg);
      }
    }

.edit-button {
  background-color: #528b4d;
  color: #fff;
  border: 1px solid #006e12;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 14px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #125017;
  border-color: #0c3d07;
  color: #e7e7e7;
}

.edit-button:focus {
  outline: none;
}

.edit-button:active .btn-primary:active {
  background-color: #0056b3;
  border-color: #044081;
}
/* 
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid black !important;
    border-top: 1px solid black;
} */

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #b4b4b4 !important;
}

.pagination-container {
  margin-top: 5px;
  display: flex;
  margin-left: 3%;
}


.error-message {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  border: 1px solid #fa6f6f;
  margin-top: 20px;
  margin-bottom: 1300px;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-line;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: red;
  font-size: 14px;
}

.btn-primary {
    border-color: #2552a7;
    margin-bottom: 20px;
}


#homeButton {
  background-color: #428d55;
  border-color: #008d21 !important;
  margin-bottom: 0px;
}

#homeButton:hover {
  background-color: #296536;
}

ul.pagination.justify-content-center {
  font-size: 12px;
}


</style>
