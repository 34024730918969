<template>
  <div>
    <page-header></page-header>
    <h1>Dashboard</h1>
    <!-- Main content section -->
    <!-- <div class="table-section">
      <table class="data-table">
        <thead>

          <tr>
            <th>Course ID</th>
            <th>Task ID</th>
            <th>Prod ID</th>
            <th>Sect ID</th>
            <th>TType</th>
            <th>Manob</th>
            <th>Level</th>
            <th>Is Exam</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table> 
    </div>-->
    <div class="explanation">
      <p>
        Here, important data regarding the Products, Projects, Logbooks and Trainees will be displayed.
      </p>
      <p>
        Various graphs and statistics will be shown.
      </p>
    </div>
    <button class="btn btn-primary rounded" id=addlogbookButton @click="goToSignature">Signature</button>
    <button class="btn btn-primary rounded" id="assignTraineeButton" @click="openSignatureModal">Signature Pop-Up</button>
    <signature-modal v-if="showSignature" @close="closeSignature"></signature-modal>

    <!-- <div class="searchbox">
      <div>
        <select style="font-size:18px;" v-model="selectedCourse" class="dropdown-container" id="courseDropdown">
          <option disabled value="">Courses</option>
          <option v-for="course in courses" :key="course.product_id" :value="course">
            {{ course.name }}
          </option>
        </select>
        <div class="classes-box">
          <div v-if="filteredClasses.length === 0" class="no-classes-message">
            No classes have been assigned
          </div>
          <div v-else>
            <div v-for="classItem in filteredClasses" :key="classItem.class_id" class="class-item">
              {{ classItem.name }}
            </div>
          </div>
        </div>
        <div class="filter-section">
          <div class="filter-heading">Filter</div>
          <div class="filter-row">
            <input type="text" placeholder="Course Name" class="filter-input">
          </div>
          <div class="filter-row">
            <input type="text" placeholder="Description" class="filter-input">
          </div>
          <div class="filter-row1">
            <input type="text" placeholder="Task ID" class="filter-input">
            <input type="text" placeholder="Prod ID" class="filter-input">
          </div>
          <div class="filter-row1">
            <input type="text" placeholder="Sect ID" class="filter-input">
            <input type="text" placeholder="TType" class="filter-input">
          </div>
          <div class="filter-row1">
            <input type="text" placeholder="Manob" class="filter-input">
            <input type="text" placeholder="Level" class="filter-input">
          </div>
        </div>
        <div class="button-container">
          <button class="clear-button">Clear</button>
          <button class="filter-button">Filter</button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import PageHeader from './Header.vue';
import axios from 'axios';
import './examinerdashboard.css';
import SignatureModal from './SignatureModal.vue';

export default {
  name: 'examinerDashboard',
  components: {
    PageHeader,
    SignatureModal,
  },
  data() {
    return {
      selectedCourse: '', // Holds the selected course
      courses: [], // Holds the list of courses
      classes: [], // Add a new data property to hold your classes
      searchQuery: '', // Holds the user's search query
      selectedClass: '',
      showSignature: false,
    };
  },
  computed: {
    // Filter courses based on the search query
    filteredCourses() {
      const query = this.searchQuery.toLowerCase();
      return this.courses.filter((course) =>
        course.name.toLowerCase().includes(query)
      );
    },
    filteredClasses() {
      if (!this.selectedCourse || !this.selectedCourse.product_id) return [];
      return this.classes.filter(classItem => classItem.product_id === this.selectedCourse.product_id);
    }
  },
  mounted() {
    this.fetchCourses(); // Fetch courses when the component is mounted
    this.fetchAllClasses();
  },
  methods: {
    openSignatureModal() {
      this.showSignature = true;
    },
    closeSignature() {
      this.showSignature = false; // Close the modal
    },
    goToSignature() {
      this.$router.push({ name: 'TraineeSignature' });
    },
    async fetchCourses() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get('/api/products', { headers });
        this.courses = response.data;
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    },
    async fetchAllClasses() {
      try {
        //eslint-disable-next-line
        //debugger;
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get('/api/classes', { headers });
        this.classes = response.data;
        console.log(this.classes);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    },
  }
};
</script>

<style scoped>
h1 {
  padding-top: 90px;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-right: 100%;
  margin-left: 2%;
}

.table-section {
  position: relative;
  margin-top: 0px;
  padding-top: 20px;
  flex: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-content: flex-start;
  overflow-y: auto;
  z-index: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 79.5%;
}

div p {
  text-align: left;
  margin-top: 20px;
  margin-left: 20px;
  padding: 20px;
  color: rgb(185, 3, 3);
  font-size: 16px;
}

div.explanation {
  border: 1px solid rgb(194, 193, 193);
  border-radius: 5px;
  margin: 20px;
}


button.btn#addlogbookButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addlogbookButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn#assignTraineeButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 200px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#assignTraineeButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}
</style>