<template>
  <div>
    <img class="logo" src="../Examiner/reports/astech.png" alt="" srcset="">
    <h1 class="welcome-title">elogbook Temporary Login</h1>
  </div>
  <div class="login-box">
    <div class="login-header">
      <h2 id="login-title">Login</h2>
    </div>
    <div class="login">
      <div class="input-container">
        <div class="space"></div>
        <input type="text" v-model="username" placeholder="Enter Email" />
      </div>
      <div class="input-container">
        <input type="password" v-model="password" placeholder="Enter Temporary Password" @keyup.enter="performLogin" />
      </div>
      <div class="input-container">
        <button type="login" v-on:click="performLogin">Login</button>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
  <password-modal v-if="showPasswordModal" @close="closePasswordModal" />
</template>

<script>
import axios from 'axios';
import PasswordModal from './PasswordModal.vue';

export default {
  name: 'TemporaryLogin',
  components: {
    PasswordModal,
  },

  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      showPasswordModal: false,
    };
  },
  methods: {
    async performLogin() {
      //eslint-disable-next-line
      debugger;
      try {
        // Send a POST request to the login API
        const response = await axios.post('/api/login', {
          email: this.username,
          password: this.password,
        });
        // Check if the login was successful
        if (response.data.data.status === true) {
          localStorage.setItem('role', response.data.data.role);
          localStorage.setItem('token', response.data.data.token);
          localStorage.setItem('username', response.data.data.username);
          localStorage.setItem('user_id', response.data.data.user_id);

          this.showPasswordModal = true;
          } else {
          // Handle login failure
          this.errorMessage = response.data.message;
        }
      } catch (error) {
        console.error('Login error:', error);
        if (error.response && error.response.data) {
          this.errorMessage = `An error occurred during login: ${error.response.data.message}`;
        } else {
          this.errorMessage = 'An error occurred during login.';
        }
      }
    },
    closePasswordModal() {
      this.showPasswordModal = false;
    },
  },
};
</script>

<style>
.welcome-title {
  margin: 5px 0;
  /* Adjust the top and bottom margin as needed */
}

.login-box {
  background-color: #fff;
  /* Background color of the box */
  border-radius: 8px;
  /* Rounded corners for the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow effect */
  padding: 10px;
  /* Padding inside the box */
  width: 700px;
  /* Adjust the width as needed */
  height: 320px;
  margin: 10px auto 0 auto;
  /* Center the box horizontally */
}

.logo {
  margin: 20px auto 0 auto;
  /* Center the box horizontally */

}

.login-header {
  background-color: #b3c5f7;
  text-align: left;
  height: 70px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: left;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.input-container {
  margin-bottom: 20px;
  /* Spacing between input elements */
  font-family: Helvetica;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="login"] {
  border-radius: 80px;
  /* Rounded corners for the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Shadow effect */
  background-color: #0a407a;
  /* Button background color */
  color: #fff;
  /* Button text color */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #042344;
  /* Hover color for the button */
}

h1 {
  font-family: Helvetica, Arial, sans-serif;
}

.error-message {
  color: #FF0000;
  font-size: 14px;
  width: 90%;
}

.space {
  height: 20px;
  /* Adjust the spacing as needed */
}
</style>