<template>
  <div>
    <h1>Logged Out</h1>

    <div class="explanation">
      <p>
        You have been logged out due to inactivity. Please log in again to continue.
      </p>
      <button class="back-button" @click="goBack">Back to Login</button>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'TimeOut',

  data() {
    return {
    };
  },

  methods: {
    goBack() {
            this.$router.push({ name: 'LoginPage' });
        },
  },
};
</script>

<style scoped>
h1 {
  padding-top: 90px;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: left;
  margin-left: 2%;
}

.table-section {
    position: relative;
    margin-top: 0px;
    padding-top: 20px;
    flex: 1;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-self: start;
    justify-content: flex-start;
    overflow-y: auto;
    z-index: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 79.5%;
}

div p {
  text-align: left;
  margin-top: 20px;
  margin-left: 20px;
  padding: 20px;
  color: rgb(185, 3, 3);
  font-size: 16px;
}

div.explanation {
  border: 1px solid rgb(194, 193, 193);
  border-radius: 5px;
  margin: 20px;
}

.back-button {
    position: absolute;
    bottom: 9%;
    /* Adjust the top position as needed */
    right: 60px;
    /* Adjust the right position as needed */
    width: 150px;
    border-radius: 5px;
    background-color: #c1e0bf;
    font-size: 14px;
    border: 2px solid black;
  padding: 3px;
}

.back-button:hover {
    background-color: #abc7a9;
    cursor: pointer;
}
</style>